export class ApiConfig {

  showDarktheme: false;

  /**
   * Static repository URL
   */
  private static readonly static_url = 'localhost/';

  /**
   * Static Staging URL
   */
  private static readonly static_staging_url = 'localhost/';

  /**
   * Returns the current HTTP method
   */
  public static get HTTP_METHOD(): string {
    if (this.IS_PRODUCTION) {
      return 'https://';
    } else {
      return 'https://';
    }
  }

  /**
   * Is production method
   *
   * @returns { boolean } - Returns if is current in production
   */
  public static get IS_PRODUCTION(): boolean {
    console.log("host: ", window.location.host);
    if (window.location.host === 'portal.gocoming.com') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Returns the current API endpoint
   */
  public static get ENDPOINT(): string {

    if (this.IS_PRODUCTION) {
      return 'api.portal.gocoming.com/';
    } else {
      // return '127.0.0.1:8000/';
      return 'api.portal.gocoming.com/';
    }
  }

  /**
   * Returns url to bank logos based on the local host
   */
  public static get BANK_LOGOS_URL(): string {
    return this.STATIC_URL + '';
  }

  /**
   * Static repository url based on the local host
   */
  private static get STATIC_URL(): string {
    if (this.IS_PRODUCTION) {
      return this.HTTP_METHOD + this.static_url;
    } else {
      return this.HTTP_METHOD + this.static_staging_url;
    }
  }

}
