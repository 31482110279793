import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES } from './menu-items';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidatorPermissions } from '../services/models/validator-permissions';

declare var $: any;

// Language
import { TranslateService } from '@ngx-translate/core';
import * as language from '../../language_configure'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  public userProfile = sessionStorage.getItem('partner_url_profile');
  public nickName = sessionStorage.getItem('partner_nick_name');
  public userMail = sessionStorage.getItem('partner_mail');
  public userGender = sessionStorage.getItem('partner_gender');

  public showMenu = '';
  public showSubMenu = '';
  public sidebarnavItems: any[];

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private validator: ValidatorPermissions,
    public translate: TranslateService,
  ) { 
    language.init(translate);
  }
  
  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
  }
}
