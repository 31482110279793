import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from './client.service';
import { ToastrService } from 'ngx-toastr';

// Language
import { TranslateService } from '@ngx-translate/core';
import * as language from '../../../language_configure'

@Injectable()
export class AuthService {
    // Logout Path
    public navigateSign = '/';
    // Toastr
    public toastrConfig = {
        timeOut: 1500,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
    };

    private http_auth = null;
    /**
     * Authentication service constructor
     *
     * @param { Router } router - Router library
     * @param { ClientService } client - HTTP client Service
     */
    constructor(
        public router: Router,
        public client: ClientService,
        private toastr: ToastrService,
        public translate: TranslateService,
    ) {
        language.init(
            translate,
            null,
            (value => {
                this.translate.get('http').subscribe(data => {
                    this.http_auth = data;
                });
            })
        );
    }

    /**
     * Make a signin request
     *
     * @param { string } mail - User email *Required
     * @param { string } password - User password *Required
     * @param { Function } destiny - Function to deal with HTTP response
     */
    public signin(mail: string, password: string, destiny: Function): void {
        const data = {
            'type': 'transfer',
            'mail': mail,
            'password': password,
        };

        this.client.post('auth/login/', data, (response: any) => {
            destiny(response);
        });
    }

    /**
     * Make a signin request
     *
     * @param { string } mail - User email *Required
     * @param { string } password - User password *Required
     * @param { Function } destiny - Function to deal with HTTP response
     */
    public recover(mail: string, destiny: Function): void {
        const data = {
            "mail": mail
        };

        this.client.post('auth/recover_password/', data, (response: any) => {
            destiny(response);
        }, false);
    }

    /**
     * Make a signout
     */
    public signout(destiny: Function): void {
        sessionStorage.removeItem('is_active');
        sessionStorage.removeItem('user_pk');
        sessionStorage.removeItem('user_auth_pk');
        // sessionStorage.removeItem('user_company_pk');
        sessionStorage.removeItem('user_name');
        sessionStorage.removeItem('user_surname');
        sessionStorage.removeItem('user_mail');
        sessionStorage.removeItem('user_phone');
        sessionStorage.removeItem('user_type');
        sessionStorage.removeItem('user_register');
        sessionStorage.removeItem('user_url_picture');
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('company_pk');
        sessionStorage.removeItem('company_name');
        sessionStorage.removeItem('company_localizer');
        destiny();
    }

    /**
     * Save User
     */
    public saveUser(data: any): void {
        // User Data
        sessionStorage.setItem('is_active', data['is_active']);
        sessionStorage.setItem('user_pk', data['pk']);
        sessionStorage.setItem('user_auth_pk', data['auth_pk']);

        sessionStorage.setItem('user_name', data['name']);
        sessionStorage.setItem('user_surname', data['last_name']);
        sessionStorage.setItem('user_mail', data['mail']);
        sessionStorage.setItem('user_phone', data['phone']);
        sessionStorage.setItem('user_type', data['type']);
        sessionStorage.setItem('user_register', data['register']);
        sessionStorage.setItem('user_url_picture', data['url_picture']);

        sessionStorage.setItem('company_pk', data['company']['pk']);
        sessionStorage.setItem('company_name', data['company']['company_name']);
        sessionStorage.setItem('company_localizer', data['company']['localizer']);
    }

    /**
     * Update User
     */
    public updateUser(data: any): void {
        // User Data
        sessionStorage.setItem('is_active', data['is_active']);
        var name = data['name'].split(' ');
        sessionStorage.setItem('user_name', name[0]);
        sessionStorage.setItem('user_surname', name[1]);
        sessionStorage.setItem('user_phone', data['phone']);
        sessionStorage.setItem('user_type', data['type']);
        sessionStorage.setItem('user_url_picture', data['url_picture']);
    }
    
    /**
     * Verify User
     */
    public verifyUser(): void {
        const token = sessionStorage.getItem('access_token');
        // TODO: expire token
        if (token === null) {
            this.signout(() => {
                this.toastr.warning('Está sessão expirou', 'Aviso!', this.toastrConfig);
                this.toastr.info(
                    this.http_auth['auth']['expire']['description'],
                    this.http_auth['auth']['expire']['title'],
                    this.toastrConfig
                );
                this.router.navigate([this.navigateSign]);
            });
        }
    }
}
