import { TranslateService } from "@ngx-translate/core";

export const settings = {
  selected: 'pt-br',
  languages: [
    'pt-br'
  ]
}

export function init(translate: TranslateService, language: string = null, func: Function = null) {
  // translate.getBrowserLang()
  if (language === null) {
    language = this.settings.selected;
  } else {
    if(this.settings.languages.indexOf(language)){
      this.settings.selected = language;
    } else {
      language = this.settings.selected;
    }
  }
  translate.use(language).subscribe(value => {
    if(func !== null) {
      func(value);
    }
  });
}

export function setLanguage(translate: TranslateService, language: string) {
  this.settings.selected = language;
  translate.use(language);
}
