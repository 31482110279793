import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'external',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  // Company
  {
    path: '/dashboard',
    title: 'companies',
    icon: 'mdi mdi-home',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  // Transfers
  {
    path: '/dashboard/transfers',
    title: 'transfers',
    icon: 'mdi mdi-ticket-confirmation',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: []
  },
  // Drivers
  {
    path: '/dashboard/system/users_system',
    title: 'user_system',
    icon: 'mdi mdi-account-multiple',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: []
  },
  // Maps
  {
    path: '/dashboard/map/users',
    title: 'user_maps',
    icon: 'mdi mdi-map',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: []
  },
  // Profile
  {
    path: '/dashboard/system/user_profile',
    title: 'user_profile',
    icon: 'mdi mdi-account',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: []
  },
];
