import { Component, AfterViewInit, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthService } from '../services/httpClient/auth.service';
import { ToastrService } from 'ngx-toastr';

// Language
import { TranslateService } from '@ngx-translate/core';
import * as language from '../../language_configure'

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})

export class NavigationComponent implements OnInit, AfterViewInit {

  name: string;
  public config: PerfectScrollbarConfigInterface = {};
  public navigateSign = '/';
  public navigateProfile = 'dashboard/system/user_profile';
  public have_notify = false;
  public have_bullet = false;
  private http_auth = null;
  public userOffice = '';

  @Input() showMinisidebar;
  @Input() showDarktheme;

  // Toastr
  public toastrConfig = {
    timeOut: 1500,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,
  };

  constructor(
    private modalService: NgbModal,
    public auth: AuthService,
    public router: Router,
    private toastr: ToastrService,
    public translate: TranslateService,
  ) { 
    language.init(
      translate,
      null,
      (value => {
        this.translate.get('http').subscribe( data => {
          this.http_auth = data;
        });
      })
    );    
  }

  // This is for Notifications
  notifications: Object[] = [ 
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Teste de notificação',
      subject: 'Enviamos essa notificação para teste',
      time: '9:30 AM'
    },
    
  ];

  // This is for Mymessages
  public mymessages = [];

  public userName = sessionStorage.getItem('user_name');
  public userSurname = sessionStorage.getItem('user_surname');

  public companyName = sessionStorage.getItem('company_name');
  public companyLocalizer = sessionStorage.getItem('company_localizer');

  public userProfile = sessionStorage.getItem('user_url_picture');
  public userMail = sessionStorage.getItem('user_mail');
  public is_active  = sessionStorage.getItem('is_active') === 'true';

  public notificationStatus = '';
  public alertBackground = '';
  public alertColor = '';

  ngOnInit() {
    this.have_notify = false
    this.have_bullet = false
    if (this.have_notify) {
      // this.mymessages.push({
      //   from: 'Red Window',
      //   subject: this.notification
      // });
    }
  }

  ngAfterViewInit() {
    const set = function() {
      const width =
        window.innerWidth > 0 ? window.innerWidth : this.screen.width;
      const topOffset = 0;
      if (width < 1170) {
        $('#main-wrapper').addClass('mini-sidebar');
      } else {
        $('#main-wrapper').removeClass('mini-sidebar');
      }
    };
    $(window).ready(set);
    $(window).on('resize', set);

    $('.search-box a, .search-box .app-search .srh-btn').on(
      'click',
      function() {
        $('.app-search').toggle(200);
      }
    );

    $('body').trigger('resize');
  }

  public profile() {
    this.router.navigate([this.navigateProfile]);
  }

  public logout() {
    this.auth.signout(() => {
      this.toastr.info(
        this.http_auth['auth']['logout']['description'], 
        this.http_auth['auth']['logout']['title'], 
        this.toastrConfig
      );
      this.router.navigate([this.navigateSign]);
    });
  }

  public onClick(){
    if ($('#main-wrapper').hasClass('mini-sidebar')) {
      $('body').trigger('resize');
      $('#main-wrapper').removeClass('mini-sidebar');
      this.onStateChangeEvent(false);
    } else {
      $('body').trigger('resize');
      $('#main-wrapper').addClass('mini-sidebar');
      this.onStateChangeEvent(true);
    }
  }

  private onStateChangeEvent = (event: any) => {
    this.showMinisidebar = event;
  }  
}
